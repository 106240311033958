import * as React from 'react';

import { getMemberNotifications } from 'libs/apis';
import type { Notification } from 'components/notification/type';

/*
  お知らせを取得するReact Hook
  未読件数の計算は行わない
  一度に取得するお知らせの件数を
*/

const useNotifications = (limit: number): Notification[] => {
  const [notifications, setNotifications] = React.useState<Notification[]>([]);

  React.useEffect(() => {
    getMemberNotifications({ limit }).then((res) => {
      const notifications = res.memberNotifications;
      setNotifications(notifications);
    });
  }, [limit]);

  return notifications;
};

export default useNotifications;
