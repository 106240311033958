/*
 * ======
 * NOTE
 * ======
 *
 * このファイルは `assets/libs/apis/generated/services/Service.ts` にある
 * 実装をほぼコピーしたもの。必要なメソッドだけコピーする。
 * なぜそれをしなければならないのか、APIクライアントの生成方法などは
 * https://gracia.esa.io/posts/13918
 * を参照のこと。
 */

import type { CancelablePromise } from './generated/core/CancelablePromise';
import { OpenAPI } from './generated/core/OpenAPI';
import { request as __request } from './generated/core/request';

import type { GetScenesResponse } from './generated/models/GetScenesResponse';
import type { getMemberNotificationsResponse } from './generated/models/getMemberNotificationsResponse';

/**
 * 規定の設定
 */
OpenAPI.BASE = '/api';
OpenAPI.WITH_CREDENTIALS = true;
OpenAPI.CREDENTIALS = 'same-origin';
OpenAPI.HEADERS = {
  'X-Api-Key': 'PMhFJKteWLdy4kt5',
};

/**
 * シーン一覧取得
 * @returns GetScenesResponse successful operation
 * @throws ApiError
 */
export const getScenes = (): CancelablePromise<GetScenesResponse> =>
  __request(OpenAPI, {
    method: 'GET',
    url: '/v1/scenes',
    errors: {
      401: '401 Unauthorized',
      500: '500 Internal Server Error',
    },
  });

/**
 * お知らせ一覧取得
 * ユーザーへのお知らせ一覧レスポンス
 *
 * @returns getMemberNotificationsResponse successful operation
 * @throws ApiError
 */
export const getMemberNotifications = ({
  limit,
  page,
}: {
  /** 取得件数(これ毎にページネーションする) **/
  limit?: number;
  /** ページ番号 **/
  page?: number;
}): CancelablePromise<getMemberNotificationsResponse> =>
  __request(OpenAPI, {
    method: 'GET',
    url: '/v1/member_notifications',
    query: {
      limit,
      page,
    },
    errors: {
      500: '500 Internal Server Error',
    },
  });
