/* eslint css-modules/no-unused-class: [2, { markAsUsed: ['size-'] }] */

// ## 参考
// https://www.joshwcomeau.com/react/modern-spacer-gif/

import * as React from 'react';
import classes from './Spacer.scss';

export type Props = {
  size: number | string;
};

const Spacer: React.FC<Props> = React.memo(({ size }) => {
  const classNames = [classes.spacer];

  const style: React.CSSProperties = {};

  if (typeof size === 'number') {
    if (size <= 32) {
      classNames.push(classes[`size-${size}`]);
    } else {
      style.width = `${size}px`;
      style.height = `${size}px`;
    }
  } else {
    style.width = size;
    style.height = size;
  }

  return <span className={classNames.join(' ')} style={style} />;
});

Spacer.displayName = 'Spacer';

export default Spacer;
