const computeFromNow = (date: Date, now: number): string => {
  // 引数で与えられた日時からコードが実行された日時までの時間（ミリ秒）
  const fromNow = now - date.getTime();

  if (fromNow < 1000 * 60) {
    // 60秒未満のときはときは「たった今」と表示
    return 'たった今';
  } else if (fromNow < 1000 * 60 * 60) {
    // 60分未満のときは「〇〇分前」と表示
    const minute = Math.floor(fromNow / (1000 * 60));
    return `${minute}分前`;
  } else if (fromNow < 1000 * 60 * 60 * 24) {
    // 24時間未満のときは「〇〇時間前」と表示
    const hour = Math.floor(fromNow / (1000 * 60 * 60));
    return `${hour}時間前`;
  } else if (fromNow < 1000 * 60 * 60 * 24 * 30) {
    // 30日未満のときは「〇〇日前」と表示
    const day = Math.floor(fromNow / (1000 * 60 * 60 * 24));
    return `${day}日前`;
  } else {
    // 以降は「〇〇ヶ月前」と表示
    const month = Math.floor(fromNow / (1000 * 60 * 60 * 24 * 30));
    return `${month}ヶ月前`;
  }
};

export default computeFromNow;
