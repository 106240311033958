type ClassNameLike = string | undefined | null;

export const merge = (...classNames: ClassNameLike[]): string => {
  let className = '';

  for (const item of classNames) {
    if (item) {
      className += item;
      className += ' ';
    }
  }

  return className;
};
