import * as React from 'react';

import * as colors from 'libs/colors';
import { merge } from 'libs/classname';
import { BellIcon } from 'components/icons';

import cs from './IconButton.scss';

type Props = {
  badgeText: string | null;
  onClick: () => void;
};

const IconButton: React.FC<Props> = React.memo(({ badgeText, onClick }) => (
  <div onClick={onClick} className={merge(cs['icon-button'], cs['hover'])}>
    <BellIcon color={colors.gray90} />
    {/* お知らせが0件のときはバッジを表示しない */}
    {badgeText && (
      <div className={cs['num-notification']}>
        <div
          className={
            badgeText === '+99' ? cs['small-font-size'] : cs['normal-font-size']
          }
        >
          {badgeText}
        </div>
      </div>
    )}
  </div>
));

IconButton.displayName = 'IconButton';

export default IconButton;
