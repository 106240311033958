/**
 * @deprecated Cloudinaryの使用を控えたいため、代わりに genPublicUrl を使用してください
 */
export const genCloudinaryUrl = (url: string, width: number): string =>
  `https://res.cloudinary.com/tanp/image/fetch/c_fill,f_auto,q_auto,w_${width}/${url}`;

const prdImageBucketRegExp = new RegExp(
  'https://s3[.-]ap-northeast-1.amazonaws.com/(gracia.tanp|gracia.tamp)/(.+)'
);
const stgImageBucketRegExp = new RegExp(
  'https://(tanp-stg-images).s3.ap-northeast-1.amazonaws.com/(.+)'
);

const prdPublicImageUrlPrefix = 'https://images.tanp.jp';
const stgPublicImageUrlPrefix = 'https://images.staging.tanp.jp';

export const genPublicUrl = (url: string, width: number): string => {
  if (
    url.startsWith(prdPublicImageUrlPrefix) ||
    url.startsWith(stgPublicImageUrlPrefix)
  ) {
    return `${url}?w=${width}`;
  }

  const prdImageBucketMatches = url.match(prdImageBucketRegExp);
  if (prdImageBucketMatches) {
    return `${prdPublicImageUrlPrefix}/${prdImageBucketMatches[1]}/${prdImageBucketMatches[2]}?w=${width}`;
  }

  const stgImageBucketMatches = url.match(stgImageBucketRegExp);
  if (stgImageBucketMatches) {
    return `${stgPublicImageUrlPrefix}/${stgImageBucketMatches[1]}/${stgImageBucketMatches[2]}?w=${width}`;
  }

  // どのパターンにもマッチしない場合は暫定としてCloudinaryのURLを生成する
  // TODO: ゆくゆくはCloudinaryを使用しないようにする(ファイル名も変更する)
  return genCloudinaryUrl(url, width);
};
